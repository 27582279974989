import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Column, Row } from '../components/Grid';
import Subfooter from '../components/Subfooter';
import '../styles/pages/_about.scss';

const AssetArchive = () => (
  <Layout title="Archive">
    <Section>
      <Container>
        <Row>
          <Column>
            <p>Unit 1</p>
            <ul className="archive-list">
              <li>
                <a
                  href="/pdfs/archive/Gen-B-U1-DesignJournal.pdf"
                  target="blank"
                >
                  Gen-B-U1-DesignJournal.pdf
                </a>
              </li>
              <li>
                <a
                  href="/pdfs/archive/Gene-Biotech-Flow-Chart-U1.pdf"
                  target="blank"
                >
                  Gene-Biotech-Flow-Chart-U1.pdf
                </a>
              </li>
              <li>
                <a
                  href="/pdfs/archive/Gene-Biotech-PacingGuide-U1.pdf"
                  target="blank"
                >
                  Gene-Biotech-PacingGuide-U1.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gene-Biotech-U1-Lab.pdf" target="blank">
                  Gene-Biotech-U1-Lab.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gene-Biotech-U1.01.pdf" target="blank">
                  Gene-Biotech-U1.01.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gene-Biotech-U1.02.pdf" target="blank">
                  Gene-Biotech-U1.02.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gene-Biotech-U1.03.pdf" target="blank">
                  Gene-Biotech-U1.03.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gene-Biotech-U1.04.pdf" target="blank">
                  Gene-Biotech-U1.04.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gene-Biotech-U1.05.pdf" target="blank">
                  Gene-Biotech-U1.05.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gene-Biotech-U1.06.pdf" target="blank">
                  Gene-Biotech-U1.06.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gene-Biotech-U1.07.pdf" target="blank">
                  Gene-Biotech-U1.07.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gene-Biotech-U1.08.pdf" target="blank">
                  Gene-Biotech-U1.08.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gene-Biotech-U1.09.pdf" target="blank">
                  Gene-Biotech-U1.09.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gene-Biotech-U1.10.pdf" target="blank">
                  Gene-Biotech-U1.10.pdf
                </a>
              </li>
            </ul>
          </Column>
          <Column>
            <p>Unit 2</p>
            <ul className="archive-list">
              <li>
                <a href="/pdfs/archive/Gen-BM-Journal-U2.pdf" target="blank">
                  Gen-BM-Journal-U2.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gen-BM-Flowchart-U2.pdf" target="blank">
                  Gen-BM-Flowchart-U2.pdf
                </a>
              </li>
              <li>
                <a
                  href="/pdfs/archive/Gen-BM-PacingGuide-U2.pdf"
                  target="blank"
                >
                  Gen-BM-PacingGuide-U2.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gen-BM-U2-Lab.pdf" target="blank">
                  Gen-BM-U2-Lab.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gen-BM-U2.1.pdf" target="blank">
                  Gene-Biotech-U2.1.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gen-BM-U2.2.pdf" target="blank">
                  Gene-Biotech-U2.2.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gen-BM-U2.3.pdf" target="blank">
                  Gene-Biotech-U2.3.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gen-BM-U2.4.pdf" target="blank">
                  Gene-Biotech-U2.4.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gen-BM-U2.5.pdf" target="blank">
                  Gene-Biotech-U2.5.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gen-BM-U2.6.pdf" target="blank">
                  Gene-Biotech-U2.6.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gen-BM-U2.7.pdf" target="blank">
                  Gene-Biotech-U2.7.pdf
                </a>
              </li>
              <li>
                <a href="/pdfs/archive/Gen-BM-U2.8.pdf" target="blank">
                  Gene-Biotech-U2.8.pdf
                </a>
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AssetArchive;
